import Menu from '../util/menu.js';
import ProcessCarousel from '../util/processCarousel.js';
import FormModal from '../util/formModal.js';
import HomeAnim from '../util/homeAnim.js';
import DownArrow from '../util/downArrow.js';

export default {
    init() {

    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        const menu = new Menu();
        const processCarousel = new ProcessCarousel();
        const formModal = new FormModal();
        const homeAnim = new HomeAnim();
        const downArrow = new DownArrow();

    },
};
